import NextLink from 'next/link'
import { Router, useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'
import hoverOpacity from '../utils/hoverOpacity'
import ButtonRounded from './ButtonRounded'
import Hamburger from './Hamburger'
import Logo from './Logo'
import MenuItemLink from './MenuItemLink'
import { PulldownMenu } from './PulldownMenu'

const Header: React.FC = () => {
  const [isTop, setIsTop] = useState(true)
  const [mobileMenuActive, setMobileMenuActive] = useState(false)
  const router = useRouter()

  const onRouteChanged = useCallback(() => setMobileMenuActive(false), [])
  useEffect(() => {
    Router.events.on('routeChangeComplete', onRouteChanged)
    Router.events.on('hashChangeComplete', onRouteChanged)
    return () => {
      Router.events.off('routeChangeComplete', onRouteChanged)
      Router.events.off('hashChangeComplete', onRouteChanged)
    }
  }, [onRouteChanged])

  const [showPulldown, setShowPulldown] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      const scrollPos = window.scrollY
      if (scrollPos > 50) {
        setIsTop(false)
      } else {
        setIsTop(true)
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Wrapper $transparent={!mobileMenuActive && isTop}>
      <Inner>
        <Logo onFocus={() => setShowPulldown(false)} />
        <StyledHamburger
          onClick={() => setMobileMenuActive(!mobileMenuActive)}
          isMenuActive={mobileMenuActive}
        />

        <Menu $isActive={mobileMenuActive}>
          <div>
            <MenuItem
              onMouseEnter={() => setShowPulldown(true)}
              onMouseLeave={() => setShowPulldown(false)}
            >
              <MenuItemLink
                href="https://biz.ryde-inc.jp/"
                label="サービス"
                onFocus={() => setShowPulldown(true)}
              />
              <PulldownMenu show={showPulldown} />
            </MenuItem>
            <MenuItem>
              <MenuItemLink
                href="/casestudy?page=1"
                label="導入事例"
                current={router.pathname.startsWith('/casestudy') ? 1 : 0}
                onFocus={() => setShowPulldown(false)}
              />
            </MenuItem>
            <MenuItem>
              <MenuItemLink
                href="https://form.run/@ryde-pass-contact"
                label="導入のお問い合わせ"
                blank
                onFocus={() => setShowPulldown(false)}
              />
            </MenuItem>
            <MenuItem>
              <MenuItemLink
                href="/news?page=1"
                label="お知らせ"
                current={router.pathname.startsWith('/news') ? 1 : 0}
                onFocus={() => setShowPulldown(false)}
              />
            </MenuItem>
            <MenuItem>
              <MenuItemLink
                href="/about"
                label="会社概要"
                current={router.pathname.startsWith('/about') ? 1 : 0}
                onFocus={() => setShowPulldown(false)}
              />
            </MenuItem>
            <MenuItem>
              <MenuItemLink
                href="/recruit"
                label="採用情報"
                blank={true}
                current={0}
                onFocus={() => setShowPulldown(false)}
              />
            </MenuItem>
          </div>

          <ButtonWrap>
            <ButtonRounded
              href="https://pass.ryde-go.com/"
              height="46"
              color="blue"
              icon="blank"
              width="232"
              blank
            >
              ユーザーさまページ
            </ButtonRounded>
          </ButtonWrap>
        </Menu>
      </Inner>
    </Wrapper>
  )
}

const Inner = styled.div`
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
  height: 80px;

  ${Media.desktop} {
    height: 80px;
  }
`
const LogoWrapper = styled(NextLink)`
  z-index: 10;
  svg {
    width: 83px;
    height: 40px;
  }

  ${Media.desktop} {
    svg {
      width: 83px;
      height: 60px;
    }

    ${hoverOpacity()}
  }
`

const Menu = styled.div<{ $isActive: boolean }>`
  display: flex;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  background: transparent;

  & > div {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-right: 6px;
  }

  ${Media.desktop} {
    display: flex;
    top: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 90px;
    overflow: visible;
    background: transparent;

    & > div {
      margin-top: 0;
      margin-right: 40px;
    }
  }

  @media screen and (max-width: 1200px) {
    & > div:first-child {
      margin-right: 20px;
    }
    & > div:last-child {
      margin-right: 0;
    }
  }

  ${Media.mobileTablet} {
    display: none;
    position: fixed;
    top: 80px;
    right: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: calc(100% - 80px);
    padding-right: 20px;
    padding-left: 20px;
    -webkit-overflow-scrolling: touch;
    background: white;

    & > div {
      display: block;
      margin-top: 20px;
      margin-right: 0;
    }

    ${(props) =>
      props.$isActive &&
      css`
        display: flex;
      `}
  }
`

const MenuItem = styled.div`
  display: inline-block;
  position: relative;
  width: auto;
  min-height: 80px;

  ${Media.desktop} {
    width: auto;
    height: 80px;
    padding: 0 20px;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 12px;
  }

  ${Media.mobileMiddle} {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 60px;
    padding: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`

const Wrapper = styled.header<{ $transparent: boolean }>`
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;
  padding: 0 16px;
  transition: background-color 0.3s;
  background: ${(props) => (props.$transparent ? 'transparent' : '#fff')};

  ${Media.desktop} {
    padding: 0 16px;
  }

  ${LogoWrapper} {
    svg {
      fill: ${theme.colors.brand};
    }
  }
`
const StyledHamburger = styled(Hamburger).withConfig({
  shouldForwardProp: (props) => props !== '$isMenuActive',
})`
  display: none;
  margin-right: -20px;

  ${Media.mobileTablet} {
    display: block;
  }
`

const ButtonWrap = styled.div`
  ${Media.mobileMiddle} {
    display: flex !important;
    justify-content: center;
    width: 100%;
  }
`

export default Header
