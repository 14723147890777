import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'
import { SolutionSection } from '../templates/NewIndexTemplate/SolutionSection'
import AppDownloadLinks from './AppDownloadLinks'
import AppDownloadSection from './AppDownloadSection'
import ButtonRounded from './ButtonRounded'
import ContactConversion from './ContactConversion'
import { Container } from './Container'
// import Conversion from './Converson'
import Heading from './Heading'
import Logo from './Logo'
import ScrollToTop from './ScrollToTop'
import Stack from './Stack'

const Footer = () => {
  const router = useRouter()

  return (
    <>
      {
        // !router.pathname.startsWith('/privacy') ? <Conversion /> : <></>
      }
      <AppDownloadSection $noMarginTop={router.pathname === '/'} />
      {router.pathname === '/' && <SolutionSection />}
      <ContactConversion />
      <footer>
        <Main>
          <Container size="large">
            <StyledScrollToTop />
            <PcGrid>
              <Stack gap="30">
                <Logo color="white" />
                <MenuGrid>
                  <MenuItem>
                    <Link
                      href="https://biz.ryde-inc.jp/"
                      passHref
                      className="item1"
                    >
                      <MenuItemLink>サービス概要</MenuItemLink>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      href="https://biz.ryde-inc.jp/usage"
                      passHref
                      className="item2"
                    >
                      <MenuItemLink>RYDE PASSの活用</MenuItemLink>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      href="https://biz.ryde-inc.jp/foundation"
                      passHref
                      className="item3"
                    >
                      <MenuItemLink>RYDE PASSの基盤を活用</MenuItemLink>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      href="https://biz.ryde-inc.jp/introduction"
                      passHref
                      className="item4"
                    >
                      <MenuItemLink>導入に関して</MenuItemLink>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link href="/casestudy?page=1" passHref className="item5">
                      <MenuItemLink>導入事例</MenuItemLink>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link href="/news?page=1" passHref className="item6">
                      <MenuItemLink>お知らせ</MenuItemLink>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link href="/about" passHref className="item7">
                      <MenuItemLink>会社概要</MenuItemLink>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      href="/recruit"
                      passHref
                      className="item8"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MenuItemLink>採用情報</MenuItemLink>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link href="/privacy" passHref className="item9">
                      <MenuItemLink>プライバシーポリシー</MenuItemLink>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link href="/security" passHref className="item10">
                      <MenuItemLink>情報セキュリティ方針</MenuItemLink>
                    </Link>
                  </MenuItem>
                </MenuGrid>
                <ButtonRounded
                  href="https://pass.ryde-go.com/"
                  width="232"
                  icon="blank"
                  blank
                >
                  ユーザーさまページ
                </ButtonRounded>
                <Stack gap="10">
                  <Heading label="APP DOWNLOAD HERE!" size="16" />
                  <AppDownloadLinks size="small" />
                </Stack>
              </Stack>
              <PcGridRight>
                <Stack gap="30">
                  <Heading label="CONTACT" size="24" />
                  <Stack gap="30">
                    <Stack gap="10">
                      <Heading label="FOR USER" size="16" />
                      <Row>
                        <MenuItem>
                          <Link
                            href="https://form.run/@ryde-customer"
                            passHref
                            className="item1"
                          >
                            <MenuItemLink>
                              ユーザーさまお問い合わせ
                            </MenuItemLink>
                          </Link>
                        </MenuItem>
                      </Row>
                    </Stack>
                    <Stack gap="10">
                      <Heading label="FOR BUSINESS" size="16" />
                      <Row>
                        <MenuItem>
                          <Link
                            href="https://form.run/@ryde-pass-contact"
                            passHref
                            className="item1"
                          >
                            <MenuItemLink>RYDE PASSを導入する</MenuItemLink>
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link
                            href="https://form.run/@ryde-pass-download"
                            passHref
                            className="item1"
                          >
                            <MenuItemLink>資料を請求する</MenuItemLink>
                          </Link>
                        </MenuItem>
                      </Row>
                    </Stack>
                  </Stack>
                </Stack>
                <BottomContent>
                  <SnsLink>
                    <span>Follow us</span>
                    <SnsLinkBtn
                      href="https://twitter.com/ryde_inc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src="/images/common/sns_twitter.png"
                        fill
                        style={{ objectFit: 'cover' }}
                        alt="Twitter"
                        sizes="10vw"
                      />
                    </SnsLinkBtn>
                  </SnsLink>
                  <Copyright>RYDE, Inc. All Rights Reserved.</Copyright>
                </BottomContent>
              </PcGridRight>
            </PcGrid>
            <SpGrid>
              <Stack gap="40">
                <Stack>
                  <Logo color="white" />
                  <MenuGrid>
                    <MenuItem>
                      <Link
                        href="https://biz.ryde-inc.jp/"
                        passHref
                        className="item1"
                      >
                        <MenuItemLink>サービス概要</MenuItemLink>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link
                        href="https://biz.ryde-inc.jp/usage"
                        passHref
                        className="item2"
                      >
                        <MenuItemLink>RYDE PASSの活用</MenuItemLink>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link
                        href="https://biz.ryde-inc.jp/foundation"
                        passHref
                        className="item3"
                      >
                        <MenuItemLink>RYDE PASSの基盤を活用</MenuItemLink>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link
                        href="https://biz.ryde-inc.jp/introduction"
                        passHref
                        className="item4"
                      >
                        <MenuItemLink>導入に関して</MenuItemLink>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link href="/casestudy?page=1" passHref className="item5">
                        <MenuItemLink>導入事例</MenuItemLink>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link href="/news?page=1" passHref className="item6">
                        <MenuItemLink>お知らせ</MenuItemLink>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link href="/about" passHref className="item7">
                        <MenuItemLink>会社概要</MenuItemLink>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link
                        href="/recruit"
                        passHref
                        className="item8"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MenuItemLink>採用情報</MenuItemLink>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link href="/privacy" passHref className="item9">
                        <MenuItemLink>プライバシーポリシー</MenuItemLink>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link href="/security" passHref className="item10">
                        <MenuItemLink>情報セキュリティ方針</MenuItemLink>
                      </Link>
                    </MenuItem>
                  </MenuGrid>
                </Stack>
                <Stack gap="30">
                  <Heading label="CONTACT" size="24" />
                  <Stack gap="30">
                    <Stack gap="10">
                      <Heading label="FOR USER" size="16" />
                      <Row>
                        <MenuItem>
                          <Link
                            href="https://form.run/@ryde-customer"
                            passHref
                            className="item1"
                          >
                            <MenuItemLink>
                              ユーザーさまお問い合わせ
                            </MenuItemLink>
                          </Link>
                        </MenuItem>
                      </Row>
                    </Stack>
                    <Stack gap="10">
                      <Heading label="FOR BUSINESS" size="16" />
                      <Row>
                        <MenuItem>
                          <Link
                            href="https://form.run/@ryde-pass-contact"
                            passHref
                            className="item1"
                          >
                            <MenuItemLink>RYDE PASSを導入する</MenuItemLink>
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link
                            href="https://form.run/@ryde-pass-download"
                            passHref
                            className="item1"
                          >
                            <MenuItemLink>資料を請求する</MenuItemLink>
                          </Link>
                        </MenuItem>
                      </Row>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack gap="40">
                  <ButtonRounded
                    href="https://pass.ryde-go.com/"
                    width="232"
                    icon="blank"
                    blank
                  >
                    ユーザーさまページ
                  </ButtonRounded>
                  <Stack gap="10">
                    <Heading label="APP DOWNLOAD HERE!" size="16" />
                    <AppDownloadLinks size="small" />
                  </Stack>
                </Stack>
              </Stack>
              <BottomContent>
                <SnsLink>
                  <span>Follow us</span>
                  <SnsLinkBtn
                    href="https://twitter.com/ryde_inc"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src="/images/common/sns_twitter.png"
                      fill
                      style={{ objectFit: 'cover' }}
                      alt="Twitter"
                      sizes="10vw"
                    />
                  </SnsLinkBtn>
                </SnsLink>
                <Copyright>RYDE, Inc. All Rights Reserved.</Copyright>
              </BottomContent>
            </SpGrid>
          </Container>
        </Main>
      </footer>
    </>
  )
}
export default Footer

const PcGrid = styled.div`
  display: flex;
  width: 100%;
  & > * {
    width: 50%;

    &:first-child {
      padding-bottom: 40px;
    }
  }

  ${Media.desktop} {
    display: flex;
  }
  ${Media.mobileTablet} {
    display: none;
  }
`
const PcGridRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
`

const SpGrid = styled.div`
  ${Media.desktop} {
    display: none;
  }
  ${Media.mobileMiddle} {
    display: block;
  }
`

const MenuGrid = styled.ul`
  display: grid;
  grid-template-areas:
    'item1 item5'
    'item2 item6'
    'item3 item7'
    'item4 item8'
    'item9 item10';
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  padding-bottom: 20px;
  row-gap: 28px;

  .item1 {
    grid-area: item1;
  }
  .item2 {
    grid-area: item2;
  }
  .item3 {
    grid-area: item3;
  }
  .item4 {
    grid-area: item4;
  }
  .item5 {
    grid-area: item5;
  }
  .item6 {
    grid-area: item6;
  }
  .item7 {
    grid-area: item7;
  }
  .item8 {
    grid-area: item8;
  }
  .item9 {
    grid-area: item9;
  }
  .item10 {
    grid-area: item10;
  }

  ${Media.mobileMiddle} {
    display: flex;
    flex-direction: column;
  }
`

const SnsLinkBtn = styled.a`
  display: block;
  position: relative;
  width: 50px;
  height: 50px;

  &:hover {
    opacity: 0.7;
  }

  ${Media.mobileMiddle} {
    width: 45px;
    height: 45px;
  }
`

const SnsLink = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  span {
    ${theme.typography.FontOverpassBold}
    font-size: 16px;
    letter-spacing: 0.1em;
  }

  & > a + a {
    margin-left: 38px;
  }

  ${Media.mobileMiddle} {
    margin-bottom: 12px;
    & > a + a {
      margin-left: 10px;
    }
  }
`

const StyledScrollToTop = styled(ScrollToTop)`
  position: absolute;
  z-index: 100;
  top: -115px;
  right: 32px;

  ${Media.mobileMiddle} {
    top: -90px;
    right: 20px;
  }
`

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: 90px;
  gap: 20px;

  ${Media.mobileMiddle} {
    margin-top: 40px;
  }
`

const Row = styled.ul`
  display: flex;
  gap: 30px;

  ${Media.mobileMiddle} {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`

const MenuItem = styled.li`
  position: relative;
  padding-left: 16px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 5px;
    width: 4px;
    height: 1px;
    transform: translateY(-50%);
    background-color: #fff;
  }

  a {
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.1em;
    text-decoration: none;
    ${theme.typography.FontNotoMedium}

    &:hover {
      opacity: 0.7;
    }
  }
`

const MenuItemLink = styled.span``

const Main = styled.section`
  position: relative;
  padding-top: 80px;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};

  & > div {
    position: relative;
  }

  ${Media.desktop} {
    padding-bottom: 40px;
  }
  ${Media.mobileTablet} {
    padding-top: 60px;
    padding-bottom: 22px;
  }
`

const Copyright = styled.p`
  ${theme.typography.FontOverpassBold}
  letter-spacing: 0.1em;

  ${Media.mobileTablet} {
    font-size: 14px;
  }
`
